import { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";

import { useGetPermissions } from "manager/hooks/api";
import { isExperianProvider } from "manager/pages/ExperianAuth/utils";
import { useGetExperianAuthState } from "manager/hooks/api/experianAuthQueries";
import { PERMISSIONS } from "shared/config/authConstants";

import useActiveCompany from "./useActiveCompany";

type hasPermissionFn = (permission: string) => boolean;

interface ArgylePermissions {
  isArgyleEnabled: boolean;
}

const getArgylePermissions = (company: any): ArgylePermissions => {
  const isArgyleEnabled = !!company?.enableArgyle;

  return {
    isArgyleEnabled,
  };
};

interface PersonaPermissions {
  isPersonaEnabled: boolean;
}

const getPersonaPermissions = (company: any): PersonaPermissions => {
  const isPersonaEnabled = !!company?.enablePersona;

  return {
    isPersonaEnabled,
  };
};

interface PrescreeningPermissions {
  isPrescreeningEnabled: boolean;
  isVerificationOfResidencyEnabled: boolean;
  canInviteLead: boolean;
  canManagePrequalificationOnProperty: boolean;
  canInviteLeadsToApply: boolean;
}

const getPrescreeningPermissions = (
  company: any,
  hasPermission: hasPermissionFn
): PrescreeningPermissions => {
  const isPrescreeningEnabled = !!company?.enablePrescreening;
  const isVerificationOfResidencyEnabled =
    !!company?.enableVerificationOfResidency;
  const canInviteLead = hasPermission(PERMISSIONS.CAN_INVITE_LEAD);
  const canManagePrequalificationOnProperty = hasPermission(
    PERMISSIONS.CAN_MANAGE_PREQUALIFICATION_ON_PROPERTY
  );
  const canInviteLeadsToApply = hasPermission(
    PERMISSIONS.CAN_INVITE_LEADS_TO_APPLY
  );

  return {
    isPrescreeningEnabled,
    isVerificationOfResidencyEnabled,
    canInviteLead,
    canManagePrequalificationOnProperty,
    canInviteLeadsToApply,
  };
};

interface CountyCriminalSearchPermissions {
  isCountyCriminalSearchEnabled: boolean;
  canRequestCountyCriminalSearch: boolean;
  canApproveDealOnCountyCriminalSearchPending: boolean;
}

const getCountyCriminalSearchPermissions = (
  company: any,
  hasPermission: hasPermissionFn
): CountyCriminalSearchPermissions => {
  const isCountyCriminalSearchEnabled = !!company?.enableCountyCriminalSearch;
  const canRequestCountyCriminalSearch = hasPermission(
    PERMISSIONS.CAN_TRIGGER_COUNTY_CRIMINAL_SEARCH
  );
  const canApproveDealOnCountyCriminalSearchPending = hasPermission(
    PERMISSIONS.CAN_APPROVE_DEAL_ON_PENDING_COUNTY_CRIMINAL_SEARCH
  );

  return {
    isCountyCriminalSearchEnabled,
    canRequestCountyCriminalSearch,
    canApproveDealOnCountyCriminalSearchPending,
  };
};

interface LumenPermissions {
  isLumenEnabled: boolean;
  isLumenAdmin: boolean;
  canAccessSensitiveDealDataInLumen: boolean;
  isLumenFormulaExporter: boolean;
}

const getLumenPermissions = (
  company: any,
  hasPermission: hasPermissionFn
): LumenPermissions => {
  const isLumenEnabled = !!company?.enableLumen;
  const isLumenAdmin = hasPermission(PERMISSIONS.LUMEN_ADMIN);
  const canAccessSensitiveDealDataInLumen = hasPermission(
    PERMISSIONS.SENSITIVE_DATA_IN_LUMEN
  );
  const isLumenFormulaExporter = hasPermission(
    PERMISSIONS.LUMEN_FORMULA_EXPORTER
  );

  return {
    isLumenEnabled,
    isLumenAdmin,
    canAccessSensitiveDealDataInLumen,
    isLumenFormulaExporter,
  };
};

interface SpecificIntegrations
  extends LumenPermissions,
    ArgylePermissions,
    PersonaPermissions,
    CountyCriminalSearchPermissions {
  isYardiEnabled: boolean;
  isYardiLROEnabled: boolean;
  canManageYardi: boolean;
}

const getSpecificIntegrations = (
  company: any,
  isIntegrationsAdmin: boolean,
  hasPermission: hasPermissionFn
): SpecificIntegrations => {
  const isYardiEnabled = !!company?.enableYardi;
  const canManageYardi = isIntegrationsAdmin && isYardiEnabled;
  const isYardiLROEnabled = !!company?.enableYardiLro && canManageYardi;

  return {
    isYardiEnabled,
    canManageYardi,
    isYardiLROEnabled,
    ...getLumenPermissions(company, hasPermission),
    ...getArgylePermissions(company),
    ...getPersonaPermissions(company),
    ...getCountyCriminalSearchPermissions(company, hasPermission),
  };
};

interface OpenApiSettings {
  isOpenApiEnabled: boolean;
  hasOpenApiUser: boolean;
  openApiEmail: string;
  webhookEventsEnabled: boolean;
  openApiWebhook: string;
}

const getOpenApiSettings = (company: any): OpenApiSettings => {
  const isOpenApiEnabled = Boolean(company?.enableOpenApi);
  const hasOpenApiUser = Boolean(company?.hasOpenApiUser);
  const openApiEmail = company?.openApiEmail;
  const webhookEventsEnabled = Boolean(company?.enableWebhookEvents);
  const openApiWebhook = company?.openApiWebhook;

  return {
    isOpenApiEnabled,
    hasOpenApiUser,
    openApiEmail,
    webhookEventsEnabled,
    openApiWebhook,
  };
};

export interface CompanyPermissions extends Permissions {
  canUseBluemoon: boolean;
  canEditOcrResults: boolean;
}

export interface Permissions
  extends SpecificIntegrations,
    OpenApiSettings,
    PrescreeningPermissions {
  // TODO: Describe company model interface
  company: any;
  hasPermission: hasPermissionFn;
  companyPermissions: CompanyPermissions;
  permissionsLoaded: boolean;
  isIntegrationsAdmin: boolean;
  hasAnyIntegrationActive: boolean;
  canUploadPropertyLogo: boolean;
  isMagicLinksEnabled: boolean;
  isMagicPortalEnabled: boolean;
  isConditionallyProceedEnabled: boolean;
  canViewFullSSN: boolean;
  canCustomizeEmails: boolean;
  isEmailCustomizationEnabled: boolean;
  enableHoldingDeposit: boolean;
  canOverrideFeesOnLeaseBuild: boolean;
  preventInvitesToTheApplicationWithoutUnit: boolean;
  canOverrideApplicantPreferredLeaseDateOnUnitAssignment: boolean;
  canRecalculateLumen?: boolean;
  canSendToPms?: boolean;
  userExperianAuthenticated?: boolean;
  canEditIncomeStreams: boolean;
}

const usePermissions = (): Permissions => {
  const { activeCompany: company } = useActiveCompany();
  const { permissions: companyPermissions } = useGetPermissions(company?.id);

  const { experianAuth } = useGetExperianAuthState(company);
  const userExperianAuthenticated = !!experianAuth?.experian?.userAuthenticated

  const permissionsLoaded =
    !isEmpty(companyPermissions) &&
    !isEmpty(company) &&
    (!!experianAuth?.experian || !isExperianProvider(company));
  const hasPermission = (permission: string) =>
    permissionsLoaded && companyPermissions[permission];

  const isIntegrationsAdmin =
    permissionsLoaded && companyPermissions[PERMISSIONS.INTEGRATIONS_ADMIN];

  const specificIntegrations = getSpecificIntegrations(
    company,
    isIntegrationsAdmin,
    hasPermission
  );

  const hasAnyIntegrationActive = specificIntegrations.isYardiEnabled;
  const canUploadPropertyLogo = hasPermission(
    PERMISSIONS.CAN_UPLOAD_PROPERTY_LOGO
  );

  const canViewFullSSN = hasPermission(PERMISSIONS.CAN_VIEW_FULL_SSN);

  const canCustomizeEmails = hasPermission(PERMISSIONS.CAN_CUSTOMIZE_EMAILS);
  const isEmailCustomizationEnabled = Boolean(
    company?.enableEmailCustomization
  );
  const preventInvitesToTheApplicationWithoutUnit = Boolean(
    company?.preventInvitesToTheApplicationWithoutUnit
  );
  const canOverrideFeesOnLeaseBuild = hasPermission(
    PERMISSIONS.CAN_OVERRIDE_FEES_ON_LEASE_BUILD
  );
  const canOverrideApplicantPreferredLeaseDateOnUnitAssignment = hasPermission(
    PERMISSIONS.CAN_OVERRIDE_APPLICANT_PREFERRED_LEASE_DATE_ON_UNIT_ASSIGNMENT
  );
  const canRecalculateLumen = hasPermission(PERMISSIONS.CAN_RECALCULATE_LUMEN);

  return {
    company,
    userExperianAuthenticated,
    hasPermission,
    companyPermissions,
    permissionsLoaded,
    isIntegrationsAdmin,
    hasAnyIntegrationActive,
    canUploadPropertyLogo,
    isMagicLinksEnabled: Boolean(company?.enableMagicLinks),
    isMagicPortalEnabled: Boolean(company?.enableMagicPortal),
    isConditionallyProceedEnabled: Boolean(company?.enableConditionallyProceed),
    canViewFullSSN,
    canCustomizeEmails,
    isEmailCustomizationEnabled,
    enableHoldingDeposit: Boolean(company?.enableHoldingDeposit),
    ...specificIntegrations,
    ...getOpenApiSettings(company),
    ...getPrescreeningPermissions(company, hasPermission),
    canOverrideFeesOnLeaseBuild,
    preventInvitesToTheApplicationWithoutUnit,
    canOverrideApplicantPreferredLeaseDateOnUnitAssignment,
    canRecalculateLumen,
    canEditIncomeStreams: hasPermission(PERMISSIONS.CAN_EDIT_INCOME_STREAMS),
  };
};

export default usePermissions;
