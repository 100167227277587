import { useQuery, useMutation } from "react-query";

import { isExperianProvider } from "manager/pages/ExperianAuth/utils";

import { NOTIFICATIONS } from "shared/config/constants";

import {
  getExperianAuthToken,
  getExperianAuthTokenByKiq,
  getExperianAuthTokenByOtp,
} from "shared/lib/api/experianAuthApi";
import { openNotification } from "shared/utils/ui";

import {
  ExperianAuthOtpParams,
  ExperianAuthTokenParams,
} from "../../interfaces/api/experianAuth";

export const useGetExperianAuthToken = () => {
  const [requestExperianAuthToken] = useMutation(
    (params?: ExperianAuthTokenParams) => getExperianAuthToken(params)
  );

  return { requestExperianAuthToken };
};

export const useGetExperianAuthState = (company = undefined) => {
  const { data, isLoading } = useQuery(
    [`GET_EXPERIAN_AUTH_TOKEN`, company?.id],
    () => getExperianAuthToken(),
    {
      enabled: !!company && isExperianProvider(company),
      cacheTime: 5000,
      staleTime: 5000,
    }
  );
  return { experianAuth: data, isExperianLoading: isLoading };
};

export const useGetExperianAuthTokenByOtp = () => {
  const [requestExperianAuthTokenByOtp] = useMutation(
    (params: ExperianAuthOtpParams) => getExperianAuthTokenByOtp(params),
    {
      onError: () => {
        openNotification(
          "Failed to submit information, please try again",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return { requestExperianAuthTokenByOtp };
};

export const useGetExperianAuthTokenByKiq = () => {
  const [requestExperianAuthTokenByKiq] = useMutation(
    (params: Record<string, number | string>) =>
      getExperianAuthTokenByKiq(params),
    {
      onError: () => {
        openNotification(
          "Failed to submit information, please try again",
          NOTIFICATIONS.error
        );
      },
    }
  );

  return { requestExperianAuthTokenByKiq };
};
